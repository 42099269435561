:root {
    --color-primary: #7ED957; /* Light green */
    --color-dark: #1B1A1A;    /* Dark color */
    --color-graydark: #434242; /* Dark gray */
    --color-background: #ffffff; /* White background */
  }
  
  .bg-primary {
    background-color: var(--color-primary);
  }
  
  .bg-dark {
    background-color: var(--color-dark);
  }
  
  .bg-graydark {
    background-color: var(--color-graydark);
  }
  
  .bg-background {
    background-color: var(--color-background);
  }
  
  .text-primary {
    color: var(--color-primary);
  }
  
  .text-dark {
    color: var(--color-dark);
  }
  
  .text-graydark {
    color: var(--color-graydark);
  }
  
  .text-background {
    color: var(--color-background);
  }
  
  .hover\:text-graydark:hover {
    color: var(--color-graydark);
  }
  